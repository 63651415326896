.parentContainer {
  display: flex;
  position: relative;
  height: auto;
  width: 100%;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 12% 0%;
}
.childContainer{
  display: flex;
  height: auto;
  width: 80%;
  justify-content: center;
  flex-wrap: wrap;
}
.imageContainer {
  order: 1;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vw;
}
.imageBox {
  width: 90%;
  height: 40%;
  box-shadow: var(--image-shadow);
  z-index: 2;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.squareImage {
  position: absolute;
  right: -50%;
  width: 100%;
}
.square{
  width: 100%;
  height: 100%;
}
.descriptor {
  z-index: 2;
  align-self: center;
  order: 1;
}
.descriptorAlignment {
  text-align: left;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .parentContainer{
    min-height: 450px;
  }
  .imageContainer {
    flex: 1.1;
    order: 2;
    height: auto;
  }
  .imageBox{
    height: 38%;
    width: 84%;
    min-height: 220px;
  }
  .descriptor {
    flex: 0.9;
  }
  .descriptorAlignment {
    text-align: right;
    margin-right: 0;
   margin-left: auto;
  }
  .squareImage{
    align-self: center;
    width: 80%;
    right: -40%;
  }
}
@media screen and (min-width:1024px){
  .imageBox{
    min-height: 240px;
  }
  .parentContainer{
    min-height: 600px;
  }
}
@media screen and (min-width:1366px){
  .parentContainer{
    min-height: 800px;
  }
  .imageBox{
    min-height: 280px;
  }
}
@media screen and (min-width:1920px){
  .parentContainer{
    min-height: 1080px;
  }
  .imageBox{
    min-height: 400px;
  }
}
@media screen and (min-width:2560px){
  .parentContainer{
    min-height: 1400px;
  }
  .imageBox{
    min-height: 560px;
  }
}